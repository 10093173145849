import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7a4c42a0 = () => interopDefault(import('../src/pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _71b1c919 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _55885602 = () => interopDefault(import('../src/pages/modify-checkout.vue' /* webpackChunkName: "pages/modify-checkout" */))
const _7cf0d1d8 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _7a4c42a0,
    name: "checkout"
  }, {
    path: "/maintenance",
    component: _71b1c919,
    name: "maintenance"
  }, {
    path: "/modify-checkout",
    component: _55885602,
    name: "modify-checkout"
  }, {
    path: "/",
    component: _7cf0d1d8,
    name: "index"
  }, {
    path: "/dedicated/checkout",
    component: _7a4c42a0,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-checkout"
  }, {
    path: "/dedicated/modify-checkout",
    component: _55885602,
    meta: {"auth0Flow":"dedicated"},
    name: "dedicated-wl-modify-checkout"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
